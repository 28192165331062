import React, { forwardRef } from "react";
import { motion } from "framer-motion";

import { images } from "../../constants";
import "./Header.scss";

const scaleVariants = {
	whileInView: {
		scale: [0, 1],
		opacity: [0, 1],
		transition: {
			duration: 1,
			ease: "easeInOut",
		},
	},
};

const Header = forwardRef(({ id }, ref) => {
	return (
		<div id={id} ref={ref} className="app__container">
			<div className="app__wrapper app__flex">
				<div className="app__header app__flex">
					<motion.div
						whileInView={{ x: [-100, 0], opacity: [0, 1] }}
						transition={{ duration: 0.5 }}
						className="app__header-info"
					>
						<div className="app__header-badge">
							<div className="badge-cmp app__flex">
								<span>👋</span>
								<div style={{ marginLeft: 20 }}>
									<p className="p-text">Hello, I am</p>
									<h1 className="head-text">Omkar</h1>
								</div>
							</div>

							<div className="tag-cmp app__flex">
								<p className="p-text">Java Developer</p>
								<p className="p-text">MERN Stack Developer</p>
							</div>
						</div>
					</motion.div>

					<motion.div
						whileInView={{ opacity: [0, 1] }}
						transition={{ duration: 0.5, delayChildren: 0.5 }}
						className="app__header-img"
					>
						<motion.img
							className="my__profile"
							src={images.profile}
							alt="profile_bg"
						/>
						<motion.img
							whileInView={{ scale: [0, 1] }}
							transition={{ duration: 1, ease: "easeInOut" }}
							src={images.circle}
							alt="profile_circle"
							className="overlay_circle"
						/>
					</motion.div>

					<motion.div
						variants={scaleVariants}
						whileInView={scaleVariants.whileInView}
						className="app__header-circles"
					>
						{[images.react, images.git, images.javascript].map(
							(circle, index) => (
								<div className="circle-cmp app__flex" key={`circle-${index}`}>
									<img src={circle} alt="profile_bg" />
								</div>
							)
						)}
					</motion.div>
				</div>
			</div>
		</div>
	);
});

export default Header;
