/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */

import React from "react";

const NavigationDots = ({ sections, activeSection, handleClick }) => {
	return (
		<div className="app__navigation">
			{sections.map(({ id, label }) => (
				<span
					key={id}
					className="app__navigation-dot"
					title={label}
					style={activeSection === id ? { backgroundColor: "#313BAC" } : {}}
					onClick={() => {
						handleClick(id);
					}}
				></span>
			))}
		</div>
	);
};

export default NavigationDots;
