import React, { useState, useEffect, forwardRef } from "react";
import { motion } from "framer-motion";

import "./About.scss";
import { urlFor, client } from "../../client";

const About = forwardRef(({ id }, ref) => {
	const [abouts, setAbouts] = useState([]);

	useEffect(() => {
		const query = '*[_type == "abouts"]';

		client.fetch(query).then((data) => {
			setAbouts(data);
		});
	}, []);

	return (
		<div id={id} ref={ref} className="app__container app__whitebg">
			<div className="app__wrapper app__flex">
				<motion.div
					whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
					transition={{ duration: 0.5 }}
					className="app__about app__flex"
				>
					<h2 className="head-text">
						Crafting Code That Bridges <span>Design</span> <br />
						And <span>functionality</span>
					</h2>

					<div className="app__profiles">
						{abouts.map((about, index) => (
							<motion.div
								whileInView={{ opacity: 1 }}
								whileHover={{ scale: 1.1 }}
								transition={{ duration: 0.5, type: "tween" }}
								className="app__profile-item"
								key={about.title + index}
							>
								<img src={urlFor(about.imgUrl)} alt={about.title} />
								<h2 className="bold-text" style={{ marginTop: 20 }}>
									{about.title}
								</h2>
								<p className="p-text" style={{ marginTop: 10 }}>
									{about.description}
								</p>
							</motion.div>
						))}
					</div>
				</motion.div>
			</div>
		</div>
	);
});

export default About;
