import React from "react";
import { BsTwitter, BsInstagram, BsGithub } from "react-icons/bs";
import { FaDownload, FaFacebookF } from "react-icons/fa";

import { images } from "../constants";

const SocialMedia = () => (
	<div className="app__social">
		<div
			className="btnGH"
			title="Github"
			onClick={() => window.open("https://github.com/OmkarKandale", "_blank")}
		>
			<BsGithub />
		</div>
		<div
			className="btnT"
			title="Twitter / X"
			onClick={() => window.open("https://x.com/OmkarKandale1", "_blank")}
		>
			<BsTwitter />
		</div>
		<div
			className="btnF"
			title="Facebook"
			onClick={() => window.open("https://www.facebook.com/okandale", "_blank")}
		>
			<FaFacebookF />
		</div>
		<div
			className="btnI"
			title="Instagram"
			onClick={() =>
				window.open("https://www.instagram.com/omkar_kandale", "_blank")
			}
		>
			<BsInstagram />
		</div>
		<div
			className="btnD"
			title="Resume / CV"
			onClick={() => window.open(images.cv, "_blank")}
		>
			<FaDownload />
		</div>
	</div>
);

export default SocialMedia;
