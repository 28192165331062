import React, { forwardRef, useState } from "react";

import { images } from "../../constants";
import { motion } from "framer-motion";
import { client } from "../../client";
import "./Footer.scss";

const Footer = forwardRef(({ id }, ref) => {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		message: "",
	});
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [loading, setLoading] = useState(false);

	const { username, email, message } = formData;

	const handleChangeInput = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = () => {
		setLoading(true);

		const contact = {
			_type: "contact",
			name: formData.username,
			email: formData.email,
			message: formData.message,
		};

		client
			.create(contact)
			.then(() => {
				setLoading(false);
				setIsFormSubmitted(true);
			})
			.catch((err) => console.log(err));
	};

	return (
		<div id={id} ref={ref} className="app__container app__whitebg">
			<div className="app__wrapper app__flex">
				<motion.div
					whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
					transition={{ duration: 0.5 }}
					className="app__footer app__flex"
				>
					<h2 className="head-text">Take a coffee & chat with me</h2>

					<div className="app__footer-cards">
						<div className="app__footer-card ">
							<img src={images.email} alt="email" />
							<a href="mailto:omkarkandale@gmail.com" className="p-text">
								omkarkandale@gmail.com
							</a>
						</div>
						<div className="app__footer-card">
							<img src={images.mobile} alt="phone" />
							<a href="tel:+91 8412008656" className="p-text">
								+91 8412008656
							</a>
						</div>
					</div>
					{!isFormSubmitted ? (
						<div className="app__footer-form app__flex">
							<div className="app__flex">
								<input
									className="p-text"
									type="text"
									placeholder="Your Name"
									name="username"
									value={username}
									onChange={handleChangeInput}
								/>
							</div>
							<div className="app__flex">
								<input
									className="p-text"
									type="email"
									placeholder="Your Email"
									name="email"
									value={email}
									onChange={handleChangeInput}
								/>
							</div>
							<div>
								<textarea
									className="p-text"
									placeholder="Your Message"
									value={message}
									name="message"
									onChange={handleChangeInput}
								/>
							</div>
							<button type="button" className="p-text" onClick={handleSubmit}>
								{!loading ? "Send Message" : "Sending..."}
							</button>
						</div>
					) : (
						<div>
							<h3 className="head-text">Thank you for getting in touch!</h3>
						</div>
					)}
				</motion.div>
			</div>
		</div>
	);
});

export default Footer;
