import React, { useState, useEffect, forwardRef } from "react";
import { motion } from "framer-motion";

import { urlFor, client } from "../../client";
import "./Skills.scss";

const Skills = forwardRef(({ id }, ref) => {
	const [experiences, setExperiences] = useState([]);
	const [skills, setSkills] = useState([]);

	useEffect(() => {
		const query = '*[_type == "experiences" && !(_id in path("drafts.**"))]';
		const skillsQuery = '*[_type == "skills"]';

		client.fetch(query).then((data) => {
			setExperiences(data);
		});

		client.fetch(skillsQuery).then((data) => {
			setSkills(data);
		});
	}, []);

	return (
		<div id={id} ref={ref} className="app__container app__whitebg">
			<div className="app__wrapper app__flex">
				<motion.div
					whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
					transition={{ duration: 0.5 }}
					className="app__skills app__flex"
				>
					<h2 className="head-text">Skills & Experiences</h2>

					<div className="app__skills-container">
						<motion.div className="app__skills-list">
							{skills.map((skill) => (
								<motion.div
									whileInView={{ opacity: [0, 1] }}
									transition={{ duration: 0.5 }}
									className="app__skills-item app__flex"
									key={skill._id}
								>
									<div
										className="app__flex"
										style={{ backgroundColor: skill.bgColor }}
									>
										<img src={urlFor(skill.icon)} alt={skill.name} />
									</div>
									<p className="p-text">{skill.name}</p>
								</motion.div>
							))}
						</motion.div>
						<div className="app__skills-exp">
							{experiences.map((experience) => (
								<motion.div
									className="app__skills-exp-item"
									key={experience._id}
								>
									<div
										className="app__skills-exp-year"
										key={experience.month + experience.year}
									>
										<p className="bold-text">
											{experience.month + " " + experience.year}
										</p>
									</div>
									<motion.div className="app__skills-exp-works">
										{experience.works.map((work) => (
											<>
												<motion.div
													whileInView={{ opacity: [0, 1] }}
													transition={{ duration: 0.5 }}
													className="app__skills-exp-work"
													title={work.desc}
													key={work._id}
												>
													<h4 className="bold-text">{work.name}</h4>
													<p className="p-text">{work.company}</p>
												</motion.div>
											</>
										))}
									</motion.div>
								</motion.div>
							))}
						</div>
					</div>
				</motion.div>
			</div>
		</div>
	);
});

export default Skills;
